import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api, { ENDPOINTS } from "../../utils/axios";
import moment from "moment";
import "moment/locale/el";

const leaderboardsModel = {
  // DATA
  leaderboardsComplete: {},
  leaderboards: {},
  selectDayOptions: [],
  selectStageOptions: [],
  intervalsMonths: [],
  intervalsWeeks: [],
  isLoading: false,
  offsets: {},
  leaderboardsTimestamps: {},

  // ACTIONS
  setLeaderboard: action((state, payload) => {
    const key = `${payload.sd.split('T')[0]}:${payload.ed.split('T')[0]}`;
  
    if (!state.leaderboards[key]) {
      state.leaderboards[key] = [];
    }
  
    const newData = payload.leaderboardsData.map((item, index) => ({
      id: item._id,
      name: item.user?.nickname || item?.teamDetails?.name || "",
      image: item.user?.profilePicture || item?.teamDetails?.emblemUrl,
      points: item?.points || item?.earnedPoints || 0,
      position: item?.position || index + 1,
    }));
  
    const currentOffset = state.offsets[key] || 0;

    if (currentOffset === 0) {
      state.leaderboards[key] = newData;
    } else {
      state.leaderboards[key] = [...state.leaderboards[key], ...newData];
    }
  }),  
  setLeaderboardComplete: action((state, { key, complete }) => {
    state.leaderboardsComplete[key] = complete;
  }),  
  resetLeaderboard: action((state) => {
    state.offsets = {};
    state.leaderboardsComplete = {};
    state.leaderboards = {};
  }),
  clearLeaderboardsForKey: action((state, key) => {
    if (state.leaderboards[key]) {
      state.leaderboards[key] = [];
    }
    state.offsets[key] = 0;
  }),
  setIntervalsWeeks: action((state, payload) => {
    state.intervalsWeeks = payload;
  }),
  setIntervalsMonths: action((state, payload) => {
    state.intervalsMonths = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setOffset: action((state, { key, offset }) => {
    state.offsets[key] = offset;
  }),
  increaseOffset: action((state, payload) => {
    state.offset += payload;
  }),
  setLastVisitedKey: action((state, payload) => {
    const { key, time } = payload;
    state.leaderboardsTimestamps[key] = time;
  }),

  // THUNKS
  getSelectData: thunk(async (actions, payload) => {
    const { data } = await api.get(ENDPOINTS.LEADERBOARDS.SELECT_OPTIONS);
    moment.locale(payload);
    // const weeks = data?.intervalsWeeks?.map((week, index) => {
    //   return {
    //     ...week,
    //     label: `${moment(week.startDate)
    //       .utc()
    //       .format("DD MMM")} - ${moment(week.endDate).utc().format("DD MMM")}`,
    //     value: { ...week },
    //   };
    // })
    // ?.reverse();
      
    let months = data?.intervalsMonths?.map((month) => ({
      ...month,
      label: `${moment(month.startDate).utc().format("MMMM YYYY")}`,
      value: { ...month },
  })) || [];

  const isGreek = payload === "el"; 

  const november = {
      startDate: "2024-11-01T00:00:00.000Z",
      endDate: "2024-11-30T23:59:59.999Z",
      label: isGreek ? "Νοέμβριος 2024" : "November 2024", 
      value: {
          startDate: "2024-11-01T00:00:00.000Z",
          endDate: "2024-11-30T23:59:59.999Z",
      },
  };

  const december = {
      startDate: "2024-12-01T00:00:00.000Z",
      endDate: "2024-12-31T23:59:59.999Z",
      label: isGreek ? "Δεκέμβριος 2024" : "December 2024", 
      value: {
          startDate: "2024-12-01T00:00:00.000Z",
          endDate: "2024-12-31T23:59:59.999Z",
      },
  };

  if (!months.some((month) => month.label === december.label)) {
      months.push(december);
  }
  if (!months.some((month) => month.label === november.label)) {
      months.push(november);
  }
  actions.setIntervalsMonths(months);
  }),
  getLeaderboardsUsers: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    const key = `${payload.startDate.split('T')[0]}:${payload.endDate.split('T')[0]}`;

    const currentOffset = state.offsets[key] || 0;

    if (state.leaderboards[key]?.length > 0 && currentOffset === 0 && payload.offset === 0) {
      const existingLength = state.leaderboards[key].length;
  
      if (existingLength < payload.limit) {
        actions.setLeaderboardComplete({ key, complete: true });
      } else {
        actions.setLeaderboardComplete({ key, complete: false });
        actions.setOffset({key, offset: existingLength});
      }
    }

    actions.setIsLoading(true);
    const isCompleteForKey = state.leaderboardsComplete[key];
    if (!isCompleteForKey) {
      let url = "";
      url += ENDPOINTS.LEADERBOARDS.LEADERBOARDS_USERS + "?challengeId=" + payload.challengeId ;

      if (payload.type !== undefined) {
        url += "&type=" + payload.type;
      }

      if (payload.startDate !== undefined && payload.endDate !== undefined) {
        
        const utcStartDate = new Date(payload.startDate);
        const utcStartDateMinus2Hours = new Date(utcStartDate);
        utcStartDateMinus2Hours.setUTCHours(utcStartDateMinus2Hours.getUTCHours() - 2);

        const utcEndDate = new Date(payload.endDate);
        const utcEndDateMinus2Hours = new Date(utcEndDate);
        utcEndDateMinus2Hours.setUTCHours(utcEndDateMinus2Hours.getUTCHours() - 2);

        url +=
          "&startDate=" +
          utcStartDateMinus2Hours.toISOString() +
          "&endDate=" +
          utcEndDateMinus2Hours.toISOString();
      }
      if (payload.offset !== undefined && payload.limit !== undefined) {
        url += "&offset=" + payload.offset + "&limit=" + payload.limit;
      }
      
      const { data } = await api.get(url);

      if (data?.length === 0 || data?.length < payload.limit) {
        actions.setLeaderboardComplete({ key, complete: true });
      } else {
        actions.setLeaderboardComplete({ key, complete: false });
        actions.setOffset({ key, offset: currentOffset + payload.limit });
      }

      actions.setLeaderboard({sd: payload.startDate, ed: payload.endDate, leaderboardsData: data})
      actions.setLastVisitedKey({ key, time: Date.now() });
      actions.setIsLoading(false);
    } 
  }),
  resetLeaderboards: thunk(async (actions) => {
    actions.resetLeaderboard();
  }),
};
export default leaderboardsModel;