import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

import PredictionPopUp from "../PredictionPopUp";
import PredictionPopUpHeader from "../PredictionPopUpHeader";

import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { isWithinTime } from "../../utils/date-hooks";
import moment from "moment";
import Button from "../Button";

const MatchCard = ({
  id,
  status,
  userBets,
  team_one,
  team_two,
  date,
  competitionName,
  betTemplate,
  filter,
}) => {
  const { t } = useTranslation();

  const [openPredictionPopUp, setOpenPredictionPopUp] = useState(false);

  const handleOpenPopUp = (id) => {
    setOpenPredictionPopUp({ id, isOpened: true });
  };

  useEffect(() => {
    if (openPredictionPopUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openPredictionPopUp]);

  const betHandler = (item) => {
    const bet = userBets?.find(
      (userBet) =>
        userBet.bettingTypeId === item.bettingTypeId && userBet.eventPart === item.eventPart
    );

    if (!bet) return "-";
    if (item.bettingTypeName.toLowerCase().includes("correct score")) {
      if (bet?.oddOptions?.[0]?.name.includes(team_one.name)) {
        return bet?.oddOptions?.[0]?.name.replace(team_one.name, "").split("-").join(" : ");
      } else if (bet?.oddOptions?.[0]?.name.includes(team_two.name)) {
        return bet?.oddOptions?.[0]?.name
          .replace(team_two.name, "")
          .split("-")
          .reverse()
          .join(" :");
      } else {
        return bet?.oddOptions?.[0]?.name.replace("Draw", "").split("-").join(" : ");
      }
    } else {
      return bet?.oddOptions?.[0]?.name;
    }
  };

  const statusVerification = (item) => {
    const bet = userBets?.find(
      (userBet) =>
        userBet.bettingTypeId === item.bettingTypeId && userBet.eventPart === item.eventPart
    );
    return bet?.earnedPoints > 0;
  };

  const earnedPointsHandler = () => {
    let sum = 0;
    userBets?.forEach((item) => {
      sum += item?.earnedPoints;
    });
    return sum.toFixed(2);
  };

  const isMatchInProgress = status === "In Progress" ||
  (moment().isAfter(moment(date)) && moment().diff(moment(date), "minutes") < 15);

  return (
    <>
      <div className={styles.matchCardContainer}>
        <PredictionPopUpHeader
          homeTeam={team_one}
          awayTeam={team_two}
          competitionName={competitionName}
          date={date}
          status={status}
        />
        {(isWithinTime(date, 48) || status !== "Pending") && (
          <div className={styles.choices_and_prediction}>
            <div className={styles.choices}>
              {betTemplate?.oddTypeSelection?.map((item, index) => {
                return (
                  <div
                    className={classNames(styles.choice_container, {
                      [styles.without_bets]: !userBets,
                    })}
                    key={index}
                  >
                    <div className={styles.type}>
                      {t(`games.bets.${item.bettingTypeName}, ${item.eventPartName}`)}
                    </div>
                    <div
                      className={classNames(styles.choice, {
                        [styles.win]: statusVerification(item) && status === "Ended",
                        [styles.lose]: !statusVerification(item) && status === "Ended",
                      })}
                    >
                      {t(betHandler(item))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.prediction}>
              {status === "Pending" && isWithinTime(date, 48) ? (
                <Button
                  label={userBets ? t("games.button.change_prediction") : t("games.button.place_prediction")}
                  size="small"
                  variant={userBets ? "secondary" : "primaryNoBorder"}
                  onClick={() => handleOpenPopUp(id)}
                />
              ) : status === "Ended" || isMatchInProgress ? (
                <div className={styles.score_card}>
                  <div className={styles.divider}>
                    <hr />
                  </div>
                  <div>
                    <p className={styles.title}>
                      {status === "In Progress" || isMatchInProgress ? t("games.results.score") : t("games.results.final_score")}
                    </p>
                    <p className={styles.score}>
                      {team_one.score} : {team_two.score}
                    </p>
                  </div>
                  {status === "Ended" && userBets?.length > 0 && (
                    <div className={styles.primary}>
                      <p className={styles.title}>{t("games.results.earned_points")}</p>
                      <div className={styles.points}>
                        {t("app.general.pts")}&nbsp;
                        {earnedPointsHandler()}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        )}
        {!isWithinTime(date, 48) && moment(date).isAfter(moment()) && (<div className={styles.before_match}>
            <p>{t("games.match_card.before_match")}</p>
        </div>)}
      </div>
      {openPredictionPopUp && (
        <PredictionPopUp
          gameId={id}
          setOpenPredictionPopUp={setOpenPredictionPopUp}
          team_one={team_one}
          team_two={team_two}
          userBets={userBets}
          competitionName={competitionName}
          date={date}
          filter={filter}
        />
      )}
    </>
  );
};
export default MatchCard;
